<template>
  <div class="flex-grow-1 pb-12">
    <v-card max-width="360" flat>
      <v-card-text>
        <v-sheet color="transparent">
          <h4 class="font-weight-black mb-2 text-center text-sm-left" :class="$vuetify.breakpoint.xs ? 'text-h4' : 'text-h3'">Sign In</h4>
          <v-sheet class="mx-auto" :max-width="$vuetify.breakpoint.xs ? '250' : '100%'">
            <p class="body-1 mb-5 text-center text-sm-left">
              Please enter the details below to login to your account
            </p>
          </v-sheet>

          <v-alert v-if="status.error" type="error" border="left" text>
            {{ status.error }}
          </v-alert>

          <v-form ref="loginForm" @submit.prevent="validateSignin()">
            <v-text-field
              v-model="data.email"
              :rules="[rules.required, rules.email]"
              label="Email Address"
              height="48"
              outlined
              dense
            ></v-text-field>

            <v-text-field
              v-model="data.password"
              @keydown.enter="validateSignin()"
              @click:append="showPassword = !showPassword"
              :rules="[rules.required]"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              height="48"
              outlined
              dense
            ></v-text-field>
          </v-form>

          <v-btn
            @click="validateSignin()"
            :loading="status.signingIn"
            color="accent gradient"
            class="rounded-lg"
            block
            >Log In</v-btn
          >

          <!-- <v-row>
            <v-col>
              <v-btn
                @click="social('facebook')"
                :disabled="status.signingIn"
                color="#3B5999"
                class="mt-2 white--text"
                single-line
                block
              >
                <v-avatar size="16" class="mr-2">
                  <v-img
                    :src="require('@/assets/facebook.svg')"
                  ></v-img>
                </v-avatar>
                <span class="text-none ls-0 heading-font">Facebook</span>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                @click="social('google')"
                :disabled="status.signingIn"
                color="white"
                class="mt-2"
                block
              >
                <v-avatar size="16" class="mr-2">
                  <v-img
                    :src="require('@/assets/google-icon.svg')"
                  ></v-img>
                </v-avatar>
                <span class="text-none ls-0 heading-font">Google</span>
              </v-btn>
            </v-col>
          </v-row> -->

          <div class="mt-5">
            <router-link
              :to="{ name: 'ResetPassword' }"
              class="body-1 black--text"
            >
              Forgot your password?
            </router-link>
          </div>

          <v-divider class="my-3"></v-divider>
          <v-row align="center" no-gutters>
            <v-col cols="8">
              <div class="black--text body-1">
                No account? 
                
                <router-link
                  :to="{ name: 'Signup' }"
                  class="black--text body-1"
                >
                  Create one here!
                </router-link>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import rules from "@/rules";

export default {
  data() {
    return {
      rules,
      validated: false,
      showPassword: false
    }
  },

  computed: {
    ...mapState({
      data: (state) => state.user.data,
      status: (state) => state.user.status,
      user: (state) => state.user.user,
    }),
  },

  watch: {
    user: function (value) {
      if (value) {
        this.$router.push(value.role == 'admin' ? { name: 'Admin' } :  { name: 'MyAccount' })
      }
    },
  },

  methods: {
    ...mapActions("user", ["signin", "socialSignin"]),

    validateSignin() {
      if (this.$refs.loginForm.validate()) {
        Promise.all([
          this.signin()
        ])
        .then(() => {
          this.$refs.loginForm.resetValidation()
        })
      }
    },

    social(type) {
      Promise.all([this.socialSignin(type)])
      .then(() => this.$router.push({ name: 'MyAccount' }))
    }
  },

  mounted() {
    this.$store.commit('user/setError', false)
  }
}
</script>

<style lang="scss">
.v-main {
  &.withBackground {
    .v-main__wrap {
      background: url("/images/bg1.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}
</style>
